.pinTest {
	display: absolute;
	width: 38px;
	height: 38px;
	border: 3px solid #ffffff;
	border-radius: 50% 50% 50% 0;
	background: #ffffff;
	margin: -46px 0 0 0px;
	transform: rotate(-45deg);
}

.pinTest img {
	position: relative;
	width: 100%;
	height: 100%;
	transform: rotate(45deg);
	border-radius: 50%;
}

.mapboxgl-control {
	background-color: #ffffff;
	padding: 10px;
	margin: 15px;
	border-radius: 5px;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
		0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
		0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}
