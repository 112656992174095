.bookignTable {
	width: 100%;
	table-layout: fixed;
}

.bookingTableHeader {
	border-left: 1px solid rgb(155, 155, 155);
	border-bottom: 1px solid rgb(155, 155, 155);
	text-align: center !important;
}

.bookingDataCell {
	border-left: 1px solid rgb(155, 155, 155);
	border-top: 1px solid rgb(155, 155, 155);
	text-align: center;
}
