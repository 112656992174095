//
// Perfect Scrollbar
//

// Base
.scroll {
	position: relative;
	overflow: hidden;

	&.scroll-pull {
		padding-right: 12px;
		margin-right: -12px;

		.ps__rail-y {
			right: -2px;
		}
	}

	&.scroll-push {
		.ps__rail-y {
			right: 5px !important;
		}
	}

	// Customization
	@include perfect-scrollbar-ver-size($custom-scrollbar-size);
	@include perfect-scrollbar-hor-size($custom-scrollbar-size);
	@include perfect-scrollbar-theme($custom-scrollbar-color);
}
