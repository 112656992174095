.people_onboarding_countryCode_container {
	border: 0px solid transparent;
	height: 45px;
	border-radius: 5px !important;
	overflow: visible;
}

.people_onboarding_countryCode_containerError {
	border: 1px solid #f64e60 !important;
}

.people_onboarding_countryCode_inputClass {
	border: 1px solid transparent !important;
	width: 100% !important;
	height: 45px !important;
	font-family: "Poppins";
	font-size: 12px !important;
	font-weight: 400 !important;
	/* color: #4a4a68 !important; */
	color: black;
	background-color: #ecf1f4 !important;
	border-radius: 5px !important;
}

.people_onboarding_countryCode_inputLoading {
	color: rgba(0, 0, 0, 0.38) !important;
}

.people_onboarding_countryCode_buttonClass {
	border-right: 1px solid white !important;
	border-left: 1px solid transparent !important;
	border-top: 1px solid transparent !important;
	border-bottom: 1px solid transparent !important;
	background-color: #ecf1f4 !important;
}

.people_onboarding_countryCode_dropdownClass {
	width: 220px !important;
	max-height: 150px !important;
	font-family: "Poppins";
	font-size: 11px !important;
	font-weight: 500 !important;
	color: #4a4a68 !important;
}
