.to-hide-text {
	width: 170px;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	/* border: 1px solid white; */
	/* height: auto; */
}

.to-hide-text:hover {
	width: 170px;
	white-space: unset;
	text-overflow: unset;
	/* margin-top:0.5rem; */
	transition: 0.2s ease-in-out;
}

.brand:hover {
	/* border: 1px solid red; */
	/* margin-top:0.5rem; */
	height: auto;
}
