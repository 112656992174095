.bookingMarker {
	background-color: transparent;
	background-size: 50px;
	background-repeat: no-repeat;
	width: 50px;
	height: 88px;
	padding-bottom: 20px;
	display: flex;
	justify-content: center;
}

.deskBookingMarkerText {
	width: 50px;
}

#bookingPopover {
	position: relative;
	border-radius: 10px;
	padding: 0px;
	/* left: -110px !important; */
	bottom: 18px !important;
	background-color: #ecf1f4;
}

#bookingPopover:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	z-index: 1;
	border-style: solid;
	border-color: #ecf1f4 transparent;
	border-width: 16px 13px 0;
	bottom: -16px;
	left: 50%;
	margin-left: -13px;
}

.bookingPopoverCard {
	margin: 10px;
	margin-bottom: 5px;
	border-radius: 10px !important;
}

.bookingMarkerText {
	margin-top: 7px;
	color: white;
	padding: 0 10px;
	cursor: pointer;
	height: 38px;
}

.mapboxgl-popup-close-button {
	display: none;
}

.mapboxgl-popup {
	max-width: 276px !important;
	max-height: 195px !important;
}

.mapboxgl-popup-content {
	padding: 0;
	border-radius: 10px;
}

/* 3D Desks */
.DeskPopover {
	position: relative;
	border-radius: 10px;
	padding: 0px;
	/* left: -110px !important;
   bottom: 18px !important; */
	margin-bottom: 50px;
	background-color: white;
	padding: 5px;
	border-radius: 5px;
}

/* marginBottom: "50px",
//top: "-50px",
backgroundColor: "white",
padding: "5px",
borderRadius: "5px" */

.DeskPopover:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	z-index: 1;
	border-style: solid;
	border-color: #ffffff transparent;
	border-width: 16px 13px 0;
	bottom: -16px;
	left: 50%;
	margin-left: -13px;
}

.triangleDivBooking {
	/* border: 1px solid red; */
}

.trianglePopupDesksBooking {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #ecf1f4;
	/* border-top: 20px solid red; */
	position: relative;
	top: 2.5rem;
	margin: 0 auto;
	margin-top: -2rem;
}

.invisibleSpaceToKeepPopupOpen {
	width: 0;
	height: 0;
	border-left: 100px solid transparent;
	border-right: 100px solid transparent;
	border-top: 40px solid transparent;
	/* border-top: 20px solid red; */
	position: relative;
	top: 3.5rem;
	margin: 0 auto;
	margin-top: -2rem;
}

a.mapboxgl-ctrl-logo {
	display: none;
}

.mapboxgl-ctrl-attrib-inner {
	display: none;
}

.addBookingBtn {
	width: 100%;
	height: 3rem;
	background-color: #c3c4dd;
	color: #323389;
	border: none;
	font-family: poppins;
	font-weight: bold;
	font-size: 11px;
	letter-spacing: 0.3px;
	border-radius: 5px;
	transition: ease-in-out 0.15s;
}

.addBookingBtn:hover {
	background-color: #a7a9cd;
	transition: ease-in-out 0.15s;
}

.mapboxgl-ctrl-pitchtoggle-3d {
	/* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==); */
}

.mapboxgl-ctrl-pitchtoggle-2d {
	/* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==); */
}

.markerZoneDivBooking {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 27px;
	color: white;
}

.zoneMarkersBooking {
	border-radius: 50%;
	border: 2px solid white;
	opacity: 0.8 !important;
	background-color: #ffba57;
	width: 32px;
	height: 32px;
	z-index: 0;
}

.popoverCardBookingMeeting {
	bottom: 9.1rem;
	padding: 15px;
	width: 235px;
	border-radius: 8px 8px 8px 8px;
	z-index: 2;
}

.popup2Booking {
	/* width: 220px; */
	/* height: 200px; */
	border-radius: 8px 8px 8px 8px !important;
}

/* #labelCanvas {
  z-index: 1 !important;
} */
