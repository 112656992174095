//
// Booking
//
@import "../../init";

.booking-geral-info-extra {
	display: block;
}
.booking-geral-info-extra-2 {
	display: block;
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	.booking-geral-info-extra {
		display: block;
	}
	.booking-geral-info-extra-2 {
		display: none;
	}
}

@include media-breakpoint-down(sm) {
	.booking-geral-info-extra {
		display: none;
	}
	.booking-geral-info-extra-2 {
		display: none;
	}
}
