//
// Checkbox
//

// Base checkbox
.checkbox {
	display: inline-block;
	position: relative;
	padding-left: 25px;
	text-align: left;
	cursor: pointer;
	font-size: 1rem;
	transition: get($checkbox-config, transition);
	margin: 0;

	&:hover > input:not([disabled]) ~ span,
	> input:focus ~ span {
		transition: get($checkbox-config, transition);
	}

	&.checkbox-disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	// Hide default browser input
	> input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}

	> span {
		@include border-radius($border-radius);
		background-color: none;
		position: absolute;
		top: 1px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: "";
			border-color: transparent;
			border-style: solid;
			border-width: 0 2px 2px 0#{"/*rtl:ignore*/"} !important;
			transform: rotate(45deg) #{"/*rtl:ignore*/"};
			margin-top: -2px;
		}
	}

	> input:checked ~ span {
		transition: get($checkbox-config, transition);
		background-color: none;

		&:after {
			display: block;
		}
	}

	// Outline Style
	&.checkbox-outline {
		> span {
			border-width: 1px;
			border-style: solid;
		}
	}

	&.checkbox-outline-2x {
		> span {
			border-width: 2px !important;
		}
	}

	// Square Style
	&.checkbox-square {
		> span {
			border-radius: 0;
		}
	}

	// Circle Style
	&.checkbox-circle {
		> span {
			border-radius: 50%;
		}
	}

	// Single mode
	&.checkbox-single {
		padding: 0;
		margin: 0;

		> span {
			top: 0px;
		}
	}

	// Input group
	.input-group & {
		margin-bottom: 0 !important;
		padding-left: 0;
	}

	// Form integration
	.form-inline & {
		margin-left: 15px;
		margin-right: 15px;
	}
}

// Configure checkbox
.checkbox {
	// Sizes
	@include checkbox-size(
		get($checkbox-config, sizes, default, base),
		get($checkbox-config, sizes, default, tick)
	);

	&.checkbox-lg {
		@include checkbox-size(
			get($checkbox-config, sizes, lg, base),
			get($checkbox-config, sizes, lg, tick)
		);
	}

	// Default theme
	@include checkbox-solid-theme(get($checkbox-config, color), $primary, $white);

	&.checkbox-outline {
		@include checkbox-outline-theme(
			get($checkbox-config, outlineColor),
			$primary,
			$primary-inverse
		);
	}

	// Color themes
	@each $name, $color in $theme-colors {
		// Default style
		&.checkbox-#{$name} {
			@include checkbox-solid-theme(get($checkbox-config, color), $color, $white);
		}

		// Light style
		&.checkbox-light-#{$name} {
			@include checkbox-solid-theme(
				theme-light-color($name),
				$color,
				theme-inverse-color($name)
			);
		}

		// Outline style
		&.checkbox-outline.checkbox-#{$name} {
			@include checkbox-outline-theme(
				get($checkbox-config, outlineColor),
				$color,
				theme-inverse-color($name)
			);
		}
	}
}

// List checkbox
.checkbox-list {
	display: flex;
	flex-direction: column;

	.checkbox {
		margin-bottom: 0.75rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Inline checkbox
.checkbox-inline {
	display: flex;

	.checkbox {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}
}
