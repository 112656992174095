#dashboard_top_row_info_main_div {
	height: 180px;
	/* border: 1px solid red; */
}

/* @media only screen and (max-width: 992px) {
    #dashboard_GlobalPeopleInfo_div {
        border: 1px solid blue;
    }
    
} */

@media only screen and (max-width: 768px) {
	#dashboard_top_row_info_main_div {
		/* border: 1px solid green; */
		height: auto;
	}

	#dashboard_GlobalPeopleInfo_div {
		/* border: 1px solid green; */
		margin-bottom: 1rem;
	}

	.top_row_info_text {
		white-space: nowrap;
	}
}
