//
// Switch
//

// Base
.switch {
	display: inline-block;
	font-size: 1rem;

	input:empty {
		margin-left: -999px;
		height: 0;
		width: 0;
		overflow: hidden;
		position: absolute;
		opacity: 0;
	}

	input:empty ~ span {
		display: inline-block;
		position: relative;
		float: left;
		width: 1px;
		text-indent: 0;
		cursor: pointer;
		user-select: none;
	}

	input:empty ~ span:before,
	input:empty ~ span:after {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		bottom: 0;
		left: 0;
		content: " ";
		transition: all 100ms ease-in;
	}

	input[disabled] {
		cursor: not-allowed;

		~ span:after,
		~ span:before {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	// State icons
	&.switch-icon {
		input:checked ~ span:after {
			@include ki(get($ki-types, check));
			line-height: 0;
		}
	}
}

// Sizing
.switch {
	// Sizing
	& {
		$switch-margin: 2px 0;
		$switch-width: 54px;
		$switch-height: 30px;
		$switch-line-height: 26px;
		$switch-space: 3px;
		$switch-checked-tick-pos: 26px;
		$switch-icon-font-size: 0.65em;

		//switch-size($margin, $width, $height, $line-height, $space, $checked-tick-pos, $icon-font-size)
		@include switch-size(
			$switch-margin,
			$switch-width,
			$switch-height,
			$switch-line-height,
			$switch-space,
			$switch-checked-tick-pos,
			$switch-icon-font-size
		);
	}

	// Small size
	&.switch-sm {
		$switch-margin: 2px 0;
		$switch-width: 38px;
		$switch-height: 24px;
		$switch-line-height: 20px;
		$switch-space: 2px;
		$switch-checked-tick-pos: 16px;
		$switch-icon-font-size: 0.55em;

		@include switch-size(
			$switch-margin,
			$switch-width,
			$switch-height,
			$switch-line-height,
			$switch-space,
			$switch-checked-tick-pos,
			$switch-icon-font-size
		);
	}

	// Large size
	&.switch-lg {
		$switch-margin: 2px 0;
		$switch-width: 72px;
		$switch-height: 40px;
		$switch-line-height: 34px;
		$switch-space: 3px;
		$switch-checked-tick-pos: 34px;
		$switch-icon-font-size: 0.75em;

		@include switch-size(
			$switch-margin,
			$switch-width,
			$switch-height,
			$switch-line-height,
			$switch-space,
			$switch-checked-tick-pos,
			$switch-icon-font-size
		);
	}

	// Form group
	.form-group.row & {
		margin-top: 0.15rem;

		&.switch-sm {
			margin-top: 0.3rem;
		}

		&.switch-lg {
			margin-top: 0rem;
			position: relative;
			top: -0.3rem;
		}
	}
}

// Colors
.switch {
	//switch-default-theme($base, $tick, $checked-base, $checked-tick-bg, $checked-tick-color)
	@include switch-default-theme(
		get($switch-config, color),
		$white,
		get($switch-config, color),
		get($switch-config, tick),
		$white
	);

	@each $name, $color in $theme-colors {
		// Default style
		&.switch-#{$name}:not(.switch-outline) {
			//switch-default-theme($base, $tick, $checked-base, $checked-tick-bg, $checked-tick-color)
			@include switch-default-theme($color, $white, $color, $white, $color);
		}

		// Outline style
		&.switch-outline.switch-#{$name} {
			//switch-outline-theme($base, $tick-bg, $checked-base, $checked-tick-bg, $checked-tick-color)
			@include switch-outline-theme(
				get($switch-config, color),
				get($switch-config, color),
				$color,
				$color,
				$white
			);
		}
	}
}
