//
// Navi
//

// Variables
$navi-link-px: 1.5rem;
$navi-link-py: 0.75rem;

$navi-header-px: 1.5rem;
$navi-header-py: 0.75rem;

$navi-footer-px: 1.5rem;
$navi-footer-py: 0.75rem;

$navi-section-px: 1.5rem;
$navi-section-py: 0.75rem;

$navi-link-elem-space: 0.75rem;

$navi-link-opacity-disabled: 0.6;

$navi-separator: $border-color;
$navi-border: $border-color;

$navi-link-bg-hover: $gray-100;
$navi-link-bg-active: $gray-100;

$navi-link-bullet-bg: $gray-300;
$navi-link-bullet-bg-hover: $primary;
$navi-link-bullet-bg-active: $primary;

$navi-link-text-font-size: 1rem;
$navi-link-text-font-size-lg: 1.1rem;
$navi-link-text-font-size-xl: 1.2rem;

$navi-link-text-color: $dark-75;
$navi-link-text-color-hover: $primary;
$navi-link-text-color-active: $primary;

$navi-link-icon-circle-width: 4rem;
$navi-link-icon-circle-width-lg: 4.5rem;
$navi-link-icon-circle-width-xl: 5rem;
$navi-link-icon-width: 2rem;
$navi-link-icon-width-lg: 3rem;
$navi-link-icon-width-xl: 4rem;

$navi-link-icon-font-size: 1.25rem;
$navi-link-icon-font-size-lg: 1.5rem;
$navi-link-icon-font-size-xl: 1.75rem;

$navi-link-icon-svg-size: 22px;
$navi-link-icon-svg-size-lg: 28px;
$navi-link-icon-svg-size-xl: 32px;

$navi-link-icon-color: $gray-500;
$navi-link-icon-light-color: $gray-400;
$navi-link-icon-color-hover: $primary;
$navi-link-icon-color-active: $primary;

$navi-link-icon-circle-bg: $gray-100;
$navi-link-icon-circle-bg-hover: $gray-200;
$navi-link-icon-circle-bg-active: $gray-200;

$navi-link-arrow-font-size: 0.6rem;
$navi-link-arrow-color: $gray-500;
$navi-link-arrow-color-hover: $primary;
$navi-link-arrow-color-active: $primary;

// Base
.navi {
	padding: 0;
	margin: 0;
	display: block;
	list-style: none;

	.navi-item {
		padding: 0;
		display: block;
		list-style: none;

		.navi-link {
			display: flex;
			align-items: center;
			padding: $navi-link-py $navi-link-px;

			.navi-icon {
				line-height: 0;
				flex-shrink: 0;

				&:before {
					line-height: 0;
				}
			}

			.navi-bullet {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				margin-right: $navi-link-elem-space;
			}

			.navi-text {
				flex-grow: 1;
			}

			.navi-label {
				line-height: 0;
				margin-left: $navi-link-elem-space;
			}

			.navi-toolbar {
				margin-left: $navi-link-elem-space;
			}

			.navi-arrow {
				line-height: 0;
				margin-left: $navi-link-elem-space;
				transition: $transition;
				color: $dark-50;

				&:before {
					line-height: 0;
					@include ki(get($ki-types, arrowNext));
					font-size: $navi-link-arrow-font-size;
				}

				&.navi-arrow-down {
					transform: rotateZ(-90deg) #{"/*rtl:ignore*/"};

					[direction="rtl"] & {
						transform: rotateZ(90deg) #{"/*rtl:ignore*/"};
					}
				}
			}
		}

		&:last-child {
			border-bottom: 0 !important;

			.navi-link {
				border-bottom: 0 !important;
			}
		}
	}

	.navi-separator {
		margin: 0;
		padding: 0;
		height: 0;
		display: block;
		border-bottom: 1px solid $border-color;

		&.navi-separator-dashed {
			border-bottom: 1px dashed $border-color;
		}
	}

	.navi-header,
	.navi-footer,
	.navi-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.navi-section {
		padding: $navi-section-py $navi-section-px;
	}

	.navi-header {
		padding: $navi-header-py $navi-header-px;
	}

	.navi-footer {
		padding: $navi-footer-py $navi-footer-px;
	}
}

// Without horizontal spacer
.navi.navi-spacer-x-0 {
	.navi-item {
		.navi-link {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

// Icon Sizes
.navi {
	.navi-item {
		.navi-link {
			@include navi-icon-size(
				$navi-link-icon-width,
				$navi-link-icon-font-size,
				$navi-link-icon-svg-size
			);
		}
	}

	&.navi-icon-lg {
		.navi-item {
			.navi-link {
				@include navi-icon-size(
					$navi-link-icon-width-lg,
					$navi-link-icon-font-size-lg,
					$navi-link-icon-svg-size-lg
				);
			}
		}
	}

	&.navi-icon-xl {
		.navi-item {
			.navi-link {
				@include navi-icon-size(
					$navi-link-icon-width-xl,
					$navi-link-icon-font-size-xl,
					$navi-link-icon-svg-size-xl
				);
			}
		}
	}

	&.navi-icon-center {
		.navi-item {
			.navi-link {
				.navi-icon {
					text-align: center;
				}
			}
		}
	}
}

// Font Sizes
.navi {
	.navi-item {
		.navi-link {
			@include navi-font-size($navi-link-text-font-size);
		}
	}

	&.navi-font-lg {
		.navi-item {
			.navi-link {
				@include navi-font-size($navi-link-text-font-size-lg);
			}
		}
	}

	&.navi-font-xl {
		.navi-item {
			.navi-link {
				@include navi-font-size($navi-link-text-font-size-xl);
			}
		}
	}
}

// Font Weight Options
.navi {
	&.navi-light {
		.navi-item {
			.navi-link {
				.navi-text {
					font-weight: $font-weight-light;
				}
			}
		}
	}

	&.navi-lighter {
		.navi-item {
			.navi-link {
				.navi-text {
					font-weight: $font-weight-lighter;
				}
			}
		}
	}

	&.navi-bold {
		.navi-item {
			.navi-link {
				.navi-text {
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	&.navi-bolder {
		.navi-item {
			.navi-link {
				.navi-text {
					font-weight: $font-weight-bolder;
				}
			}
		}
	}

	&.navi-boldest {
		.navi-item {
			.navi-link {
				.navi-text {
					font-weight: $font-weight-boldest;
				}
			}
		}
	}
}

// Default Theme
.navi {
	.navi-item {
		.navi-link {
			// Default transitions
			@include navi-link-transition($transition);

			// Default state
			@include navi-link-theme(
				$navi-link-bullet-bg,
				$navi-link-text-color,
				$navi-link-icon-color,
				$navi-link-arrow-color,
				null,
				null
			);

			// Disabled state
			&.disabled {
				opacity: $navi-link-opacity-disabled;
			}

			&:hover {
				@include navi-link-transition($transition);
				@include navi-link-theme(
					$navi-link-bullet-bg-hover,
					$navi-link-text-color-hover,
					$navi-link-icon-color-hover,
					$navi-link-arrow-color-hover,
					null,
					null
				);
			}

			&.active {
				@include navi-link-transition($transition);
				@include navi-link-theme(
					$navi-link-bullet-bg-active,
					$navi-link-text-color-active,
					$navi-link-icon-color-active,
					$navi-link-arrow-color-active,
					null,
					null
				);
			}
		}
	}
}

// Light Icon Theme
.navi.navi-light-icon {
	.navi-item {
		.navi-link {
			// Default transitions
			@include navi-link-transition($transition);

			// Default state
			@include navi-link-theme(
				$navi-link-bullet-bg,
				$navi-link-text-color,
				$navi-link-icon-light-color,
				$navi-link-arrow-color,
				null,
				null
			);

			// Disabled state
			&.disabled {
				opacity: $navi-link-opacity-disabled;
			}

			&:hover {
				@include navi-link-transition($transition);
				@include navi-link-theme(
					$navi-link-bullet-bg-hover,
					$navi-link-text-color-hover,
					$navi-link-icon-color-hover,
					$navi-link-arrow-color-hover,
					null,
					null
				);
			}

			&.active {
				@include navi-link-transition($transition);
				@include navi-link-theme(
					$navi-link-bullet-bg-active,
					$navi-link-text-color-active,
					$navi-link-icon-color-active,
					$navi-link-arrow-color-active,
					null,
					null
				);
			}
		}
	}
}

// Border Style
.navi {
	&.navi-border {
		.navi-item {
			.navi-link {
				border-bottom: 1px solid $border-color;
			}
		}
	}
}

// Border Radius
.navi {
	&.navi-link-rounded {
		.navi-item {
			.navi-link {
				border-radius: $border-radius;
			}
		}
	}

	&.navi-link-rounded-lg {
		.navi-item {
			.navi-link {
				border-radius: $border-radius-lg;
			}
		}
	}
}

// Background Color Style
.navi {
	&.navi-hover {
		.navi-item {
			.navi-link:hover {
				background-color: $navi-link-bg-hover;
			}
		}
	}

	&.navi-active {
		.navi-item {
			.navi-link.active {
				background-color: $navi-link-bg-active;
			}
		}
	}
}

// Accent Style
.navi {
	&.navi-accent {
		.navi-item {
			.navi-link {
				position: relative;

				&:before {
					display: block;
					left: 0;
					top: 8px;
					bottom: 8px;
					position: absolute;
					width: 4px;
					background-color: transparent;
					transition: $transition;
					@include border-radius(2px);
					content: " ";
				}

				&.active {
					&:before {
						background-color: $primary;
						transition: $transition;
					}
				}
			}
		}

		// Theme colors
		@each $name, $color in $theme-colors {
			&.navi-#{$name} {
				.navi-item {
					.navi-link {
						&:hover {
							@include navi-link-theme(null, $color, $color, $color, null, null);
						}

						&.active {
							@include navi-link-theme(null, $color, $color, $color, null, null);

							&:before {
								background-color: $color;
							}
						}
					}
				}
			}
		}
	}
}
