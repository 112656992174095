.attendanceList-people-container:hover .attendanceList-sendMessageModal {
	display: flex;
	align-items: center;
}

.attendanceList-sendMessageModal {
	display: none;
}

.actions-header {
	margin-left: 10px;
}

.no-right-border {
	border-right: none !important;
}
