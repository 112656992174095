.live-icon-marker {
	// border: 1px solid red;
	transform: translateX(-50%) translateY(-50%);
	width: 36px; // ← if width or height change the border-radius must change as well, so shadow keeps round
	height: 36px;
	pointer-events: none;
	//z-index: 1;

	&:before {
		content: "";
		position: relative;
		display: block;
		width: 300%;
		height: 300%;
		box-sizing: border-box;
		margin-left: -100%;
		margin-top: -100%;
		border-radius: 55px;
		//   background-color: #01a4e9;
		// background-color: #4299FF;
		background-color: #3a0ca3;
		animation: pulse-ring 1.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}

	// &:after {
	//   content: '';
	//   position: absolute;
	//   left: 0;
	//   top: 0;
	//   display: block;
	//   width: 100%;
	//   height: 100%;
	// //   background-color: white;
	//   border-radius: 15px;
	//   box-shadow: 0 0 8px rgba(0,0,0,.3);
	//   animation: pulse-dot 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
	// }
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.33);
	}
	70%,
	100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}
