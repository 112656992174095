.container_drawer_list_bar_content {
	/* display: flex; */
	margin: 0;
	top: 0;
	position: absolute;
	right: 0;
	/* border: 3px solid green; */

	width: 340px;
	height: 600px;
	background: var(--Utilitarian-Gray-Palette-0, #fff);
	box-shadow: inset -2px 0px 10px 2px rgba(0, 0, 0, 0.15);
}

.how-to-info {
	display: flex;
	/* width: 304px; */
	/* height: 31px; */
	padding: 1rem 1rem;
	flex-direction: column;
	justify-content: center;
	/* flex-shrink: 0; */
}

.how-to-info span {
	color: var(--Utilitarian-Neutrals-Light-Slate, #8c8ca1);
	text-align: center;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 138.462% */
}
