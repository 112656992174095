#sticky_button {
	min-width: 36px;
	max-width: 36px;
	height: 36px;
	position: fixed;
	bottom: 85px;
	right: 20px;
	opacity: 0.8;
	/* animation-name: animation;
	animation-duration: 500ms; */
}

#sticky_button:hover {
	opacity: 1;
}

@media (max-width: 991.98px) {
	#sticky_button {
		bottom: 70px;
		right: 12px;
		width: 36px;
		height: 32px;
		/* animation-name: animation-mobile;
		animation-duration: 1000ms; */
	}
}

@keyframes animation {
	0% {
		bottom: 70px;
		right: 20px;
	}
	100% {
		bottom: 85px;
		right: 20px;
	}
}
@keyframes animation-mobile {
	0% {
		bottom: 60px;
		right: 12px;
	}
	100% {
		bottom: 70px;
		right: 12px;
	}
}
