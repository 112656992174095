/* Navbar colocar z-index p ficar sempre acima dos controls do map */
/* #kt_header {
	z-index: 1000;
} */

/* #kt_header_mobile {
	z-index: 1000;
} */

.audit_tool_date_box {
	background-color: #fff;
}

#back_1_step_audit_tool:active {
	background-color: #73a3f5;
}

#back_30_step_audit_tool:active {
	background-color: #73a3f5;
}

#forward_1_step_audit_tool:active {
	background-color: #73a3f5;
}

#forward_30_step_audit_tool:active {
	background-color: #73a3f5;
}

#play_step_audit_tool:active {
	background-color: #73a3f5;
}

#pause_step_audit_tool:active {
	background-color: #73a3f5;
}

#forward_end_step_audit_tool:active {
	background-color: #73a3f5;
}
#backward_start_step_audit_tool:active {
	background-color: #73a3f5;
}

.audit_tool_date_box {
	border-radius: 4px;
	border: 1px solid #2673f0;
	/* margin-right: 1.5rem; */
	height: 31px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 185px;
}

.audit_tool_date_box_text {
	color: #2673f0;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 400;
	margin-right: 1rem;
	white-space: nowrap;
}

.audit_tool_date_box_number {
	color: #4a4a68;
	font-family: Poppins;
	font-size: 12px;
	font-weight: 600;
	white-space: nowrap;
}
