.broadcast-custom-selection {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 14px;
	margin-bottom: 10px;
	border-radius: 5px;
	background: var(--dorian, #ecf1f4);
	max-width: 49% !important;
}

.broadcast-custom-selection:hover {
	background-color: #d6d9e1;
}

.broadcast-custom-selection div {
	width: 25px;
	height: 25px;
	border-radius: 100%;
}

.active_msg {
	border: 1px solid #2673f0;
	color: #2673f0;
	background-color: #dde9fd;
}
.active_msg section svg {
	fill: #2673f0;
}

.inactive_msg {
	border: 1px solid var(--grey, #d6d9e1);
}

.broadcast-custom-selection div.active_msg {
	border: 8px solid #2673f0;
	background-color: white;
}

.broadcast-custom-selection div.inactive_msg {
	border: 2px solid #b7b7b7;
}

/* Dropdown button */
.message-broadcast-dropdown .dropdown-menu {
	margin-top: 5px !important;
	padding: 5px !important;
}

.message-broadcast-dropdown a {
	border-radius: 5px !important;
}

.box-title {
	color: var(--Utilitarian-Neutrals-Slate, #4a4a68);

	/* Body/M/Medium */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
}

.disabled-box-title {
	color: var(--Utilitarian-Neutrals-Slate, #4a4a68);

	/* Body/M/Medium */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
	opacity: 0.5;
}

.modal-title {
	color: var(--Utilitarian-Neutrals-Dark, #1c1c3b);

	/* Body */
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%; /* 25.6px */
}

.select-counter {
	color: var(--Main-Colors-Primary-Clear-Blue, #2673f0);
	text-align: center;

	/* Body/M/Bold */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	align-self: center;
}

.icon-preview {
	width: 31px;
	height: 31px;
	border-radius: 50%;
	background: #dde9fd;
	/* border: 2px solid #fff; */
	display: flex;
	justify-content: center;
	align-items: center;
	color: #99bdf8;

	/* position: "sticky";
	margin-left: "29px";
	margin-bottom: "-13px";
	z-index: "1";
	width: "12px";
	height: "12px";
	border-radius: "14px"; */
}

.icon-preview img,
.icon-preview svg {
	width: inherit;
	height: inherit;
	border-radius: 50%;
	border: 2px solid #fff;
	fill: #99bdf8;
}
