.hoverAction {
	display: none;
}

.read,
.notRead {
	margin-right: 20px !important;
	margin-bottom: 10px !important;
	border: 1px solid transparent;
}

.read:hover {
	border: 1px solid #2673f0 !important;
}

.warningHover:hover {
	border: 1px solid #f6b952 !important;
}

.criticalHover:hover {
	border: 1px solid #e65019 !important;
}

.notRead:hover {
	border: 1px solid #d1d1d1 !important;
}

.card:hover .hoverAction {
	display: flex;
}

.FirebaseModal-time {
	font-weight: 400;
}

.perfect-scroll .ps__thumb-y {
	width: 11px;
	opacity: 1 !important;
	border-radius: 2px !important;
	background: #d1d1d1 !important;
	right: 0;
}

.perfect-scroll .ps__rail-y {
	width: 11px;
	opacity: 1 !important;
	border-radius: 2px !important;
	background: #efeeee !important;
}

.badge-start-icon {
	color: "#4A4A68";
}
