.timelineDateButton {
	padding: 3px;
	border-radius: 5px;
	background-color: white;
}

.timelineDateButton:hover {
	background-color: rgb(245, 245, 245);
}

.timelineDateButton:active {
	background-color: rgb(230, 230, 230);
}
