.auth-customer {
	max-height: 40vh;
	overflow: auto;
}

.auth-customer-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 14px;
	margin-bottom: 20px;
	border-radius: 5px;
	border: 1px solid var(--grey, #d6d9e1);
	background: var(--dorian, #ecf1f4);
}

.auth-customer-container:hover {
	background-color: #d6d9e1;
}

.auth-customer-container div {
	width: 25px;
	height: 25px;
	border-radius: 100%;
}

.auth-customer-container div.active {
	border: 8px solid #337dfd;
}

.auth-customer-container div.inactive {
	border: 2px solid #b7b7b7;
}

.login-input-padding .MuiInputBase-input {
	padding: 18.5px 14px;
	border: 0px solid #b7b7b7;
	border-radius: 4px;
}

#emailField {
	border: 1px solid #b7b7b7;
}

#emailField:hover {
	border: 1px solid black;
	border-radius: 5px;
}
