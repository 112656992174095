.popup_stick_active_anchors {
	border: 0;
}

.anchor_active_popup_edit_btn {
	background-color: #dde9fd;
	width: 30px;
	height: 30px;
}

.anchor_active_popup_edit_btn:hover {
	background-color: #bfd5fb;
}

.anchor_position_popup_edit_btn {
	background-color: #fbe6de;
	width: 30px;
	height: 30px;
}

.anchor_position_popup_edit_btn:hover {
	background-color: #f6cab9;
}

.anchor_position_popup_drag_btn {
	background-color: #dbdbdb;
	width: 30px;
	height: 30px;
}

.anchor_position_popup_drag_btn:hover {
	background-color: #c1c1c1;
}

.anchor_position_popup_drag_btn_active {
	background-color: #6d6d6d;
	width: 30px;
	height: 30px;
}
.anchor_position_popup_drag_btn_active:hover {
	background-color: #4f4f4f;
}

.popup_title {
	color: #4a4a68;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 700;
	padding: 9px 10px;
}

.big_image_modal_anchors_content {
	background-color: #ecf1f4;
	font-family: "Poppins";
	height: 47rem;
	width: 60rem;
}

.big_image_modal_anchors_content_active {
	background-color: #ecf1f4;
	font-family: "Poppins";
	height: 47rem;
	width: 60rem;
}

.expanded_drawer_anchors {
	/* background: gold; */
	/* visibility: visible; */
	background-color: white;
	/* display: block; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	opacity: 1;
	height: 250px;
	max-height: 400px;
	transition: visibility 0s, opacity 0.3s, max-height 0.5s linear;
	/* animation: expandAnimation 0.9s ease forwards; */
}

/* Note: This makes the border of the carousel to be the same has the image inside it when is slides */
.carousel-inner {
	border-radius: 5px 5px 0px 0px;
}

#top_row_anchors_page_desktop {
	display: flex;
}

#top_row_anchors_page_mobile {
	display: none;
}

.anchors_page_value_total {
	font-size: 24px;
}

.anchors_page_marker_divs {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.anchors_page_text {
	font-size: 12px;
}

@media only screen and (max-width: 1400px) {
	.anchors_page_value_total {
		font-size: 22px;
	}

	.anchors_page_marker_divs {
		width: 30px;
		height: 30px;
	}
	.anchors_page_text {
		font-size: 11px;
	}
}
@media only screen and (max-width: 1300px) {
	.anchors_page_value_total {
		font-size: 18px;
	}

	.anchors_page_marker_divs {
		width: 28px;
		height: 28px;
	}
}

/* Mobile  */
@media only screen and (max-width: 1340px) {
	#top_row_anchors_page_desktop {
		display: none;
	}

	#top_row_anchors_page_mobile {
		display: flex;
		padding: 15px;
	}

	.anchors_page_marker_divs {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.anchors_page_text {
		font-size: 12px;
	}

	.anchors_page_value_total {
		font-size: 24px;
	}
}

@media only screen and (max-width: 576px) {
	.force-no-padding {
		padding: 0 !important;
	}
}

.jss8 {
	background-color: transparent !important;
	color: initial !important;
}
