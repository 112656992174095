.options {
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.option-content {
	display: none;
}

.options-button {
	display: block;
	padding: 7px;
	padding-top: 4px;
	width: 30px;
	height: 30px;
}

.options:hover .option-content {
	display: block;
	padding: 2px 8px;
}

.options:hover .options-button {
	display: none;
}

.options-switchs {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #ecf1f4;
	border-radius: 5px;
	padding: 2px 10px;
	margin-top: 8px;
	margin-bottom: 8px;
}

/* Fullscreen */
.fullscreen-button {
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	padding: 2px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fullscreen-button_disabled {
	background-color: rgba(128, 128, 128, 0.5);
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	padding: 2px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #5c5c5c;
	opacity: 0.5;
}

.fullscreen-button:hover {
	background-color: rgb(200, 200, 200);
}

.fullscreen-button:active {
	background-color: rgb(150, 150, 150);
}

/* Occupancy */
.occupancy-button {
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	padding: 2px;
	width: 30px;
	height: 30px;
}

.occupancy-button:hover {
	background-color: rgb(200, 200, 200);
}

.occupancy-button:active {
	background-color: rgb(150, 150, 150);
}
.occupancy-button-pressed {
	background-color: rgb(200, 200, 200);
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
	padding: 2px;
	width: 30px;
	height: 30px;
}

.occupancy-button-pressed:hover {
	background-color: rgb(150, 150, 150);
}

.occupancy-button-pressed:active {
	background-color: rgb(100, 100, 100);
}

/* Show Aps */
.showAPs-button {
	background-color: white;
	border-radius: 5px;
	/* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
	border: 2px solid #cfcdc9;
	padding: 2px;
	width: 33px;
	height: 33px;
	margin-right: -2px;
}

.showAPs-button:hover {
	background-color: rgb(200, 200, 200);
	transition: background-color 0.2s ease;
}

.showAPs-button:active {
	background-color: rgb(150, 150, 150);
}
.showAPs-button-pressed {
	background-color: rgb(200, 200, 200);
	border-radius: 5px;
	/* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2); */
	border: 2px solid #cfcdc9;
	padding: 2px;
	width: 33px;
	height: 33px;
	margin-right: -2px;
}

.showAPs-button-pressed:hover {
	background-color: rgb(150, 150, 150);
	transition: background-color 0.2s ease;
}

.showAPs-button-pressed:active {
	background-color: rgb(100, 100, 100);
}

/* ChangeFloorsControls  */
/* .floorplans_control_toggle_dashboard_span {

width: 4rem;
display: inline-block;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.floorplans_control_toggle_dashboard_span:hover {

   white-space: unset;
   text-overflow: unset;
   width: 98%;
   } */

/* .floorplans_control_toggle_dashboard_span {
   display: none;
}

.floorplans_control_toggle_dashboard_span:hover {
   display: block;
} */

.floorplans_control_toggle_dashboard_menu {
	max-height: 300px;
	overflow-y: auto;
	/* overflow-y: hidden; */
}

.floorplans_control_toggle_dashboard_item {
	padding: 0.3rem 1rem !important;
}

.floorplans_control_toggle_dashboard_item.active {
	background-color: #f2f2f2 !important;
	color: black !important;
}

/* on mouse click selection */
.floorplans_control_toggle_dashboard_item:active {
	background-color: #f2f2f2 !important;
	color: black !important;
}

.floorplans_control_toggle_dashboard {
	/* background-color: white !important; */
	background-color: #2673f0 !important;
	border-radius: 4px !important;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1) !important;
	/* height: 30px; */
	/* width: 30px; */
	height: 35px;
	width: auto;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	border: none !important;
	/* min-width: 10rem;
   width: auto; */
}

.floorplans_control_toggle_dashboard_disabled {
	/* background-color: white !important; */
	background-color: rgba(128, 128, 128, 0.5) !important;
	border-radius: 4px !important;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1) !important;
	/* height: 30px; */
	/* width: 30px; */
	height: 35px;
	width: auto;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	border: none !important;
	/* min-width: 10rem;
   width: auto; */
}

.floorplans_control_toggle_dashboard::after {
	content: none !important;
}

.floorplans_control_toggle_dashboard:hover {
	background-color: #004ecc !important;
}

.floorplans_control_toggle_dashboard:active {
	background-color: #0f2a5e !important;
}

.reset_path_div {
	background-color: white;
	border-radius: 5px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.reset_path_button {
	display: block;
	padding: 4px;
	/* padding-top: 4px; */
	width: 30px;
	height: 30px;
}

.reset_path_div:hover {
	background-color: rgb(150, 150, 150);
}

#disable_change_floors {
	background-color: red;
}
