.people-onboarding.video-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-color: rgba(10, 27, 61, 0.6);
	width: 100%;
	height: 100%;
	opacity: 1;
	transition: opacity 0.2s ease-out;
}

.people-onboarding.video-overlay.playing {
	opacity: 0;
}

.people-onboarding.video-overlay .duration {
	position: absolute;
	bottom: 10px;
	right: 10px;
	color: #fff;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

.people-onboarding.final-quiz .title {
	color: #4a4a68;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 170%;
}

.people-onboarding.final-quiz .answer {
	border-radius: 4px;
	background: #f7f7f7;
}
