//
// Dashboard
//
@import "../../init";

.dashboard-geral-info-extra {
	display: block;
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	.dashboard-geral-info-extra {
		display: block;
	}
}

@include media-breakpoint-down(sm) {
	.dashboard-geral-info-extra {
		display: none;
	}
}
